import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/integrations-slack.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const SlackIntegrationPage = ({ data }) => (
  <Layout>
    <SEO
      title="Connect Slack to your Odown monitors"
      description="Receive real-time alerts from Odown to your preferred Slack channels."
      pathname={`/integrations/slack/`}
      image={{
        src: "/general-image-og--odown_integration_slack.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid}/>
    <Description />
    <CombinedSections />
    <CombinedSections2 />
    <Howtouse />
    <SuperchargeYourIncidentResponseSection />
    <BuiltforTeamsofAllSizes />
    <SecureandReliable />
    <TryOdownSection />
    <ClosingSection />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = ({ imageData }) => {
    const [getStartedButton, setGetStartedButton] = useState({
      name: "Get started for free",
      link: "https://app.odown.io/signup",
    });
  
    return (
      <div className="u-features slack-hero">
        <div className="flex-container">
          <div className="text-section">
            <h1 className="custom-heading-title">
              Stay on Top of Downtime with Odown’s Slack Integration
            </h1>
            <div className="u-features__heading-btn center-button">
              <OutboundLink
                className="custom-btn custom-btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blank"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
          <div className="image-section">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={imageData}
              alt="Integration - Slack"
            />
          </div>
        </div>
      </div>
    );
  };  
const Description = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <p className="u-main-feature_tc-desc2">
        Downtime affects crucial business aspects like revenue, 
        brand reputation and user experience that impact overall growth. 
        Presently Down provides new Slack Integration service that has more 
        features than traditional monitoring systems. These alerts make your
        monitoring work very efficient by giving you diverse notifications 
        which help to track website performance in team channels.      
       </p>
     </div>
  </section>
)

const CombinedSections = ({ imageData }) => (
    <div className="sections-container">
        <h2 className="centered-heading">Why Connect Odown with Slack?</h2>
        <div className="real-time-section">
            <RealTimeNotificationSection imageData={imageData} />
        </div>
        <div className="streamlined-section">
            <StreamlinedIncidentManagementSection imageData={imageData} />
        </div>
    </div>
);

  const RealTimeNotificationSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Real-Time Notifications</h3>
              <p className="u-main-feature_tc-desc">
                No more refreshing status dashboards or missing critical email alerts. 
                Odown’s Slack Integration instantly notifies you in your team’s chosen 
                channel the moment your site experiences downtime or performance issues. 
                This ensures your entire organization—from developers to customer support—can 
                respond promptly and decisively.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Immediate Visibility: </strong>
                Alerts pop up in Slack the second Odown detects an anomaly.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Faster Response Times: </strong> 
                Reduce the lag between detection and action.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Centralized Communication: </strong> 
                Keep everyone in the loop without extra emails or meetings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const StreamlinedIncidentManagementSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Streamlined Incident Management</h3>
              <p className="u-main-feature_tc-desc">
                When an outage or performance issue arises, every second matters. 
                With Odown’s Slack Integration, you can manage the entire incident 
                resolution process inside Slack—from the initial alert to the final resolution. 
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Focused Discussions: </strong>
                Your team can troubleshoot, share logs or screenshots, 
                and collaborate in one dedicated place.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Clear Ownership: </strong> 
                Tag specific team members or roles to assign tasks and track progress.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Historical Context: </strong> 
                Slack’s archive ensures important incident details
                never get lost—perfect for post-incident reviews.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const CombinedSections2 = () => (
    <div className="sections-container">
        <div className="real-time-section">
            <CustomizableForYourWorkflowSection />
        </div>
        <div className="streamlined-section">
            <A360ViewOfPerformanceSection />
        </div>
    </div>
);

const CustomizableForYourWorkflowSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Customizable for Your Workflow</h3>
              <p className="u-main-feature_tc-desc">
              Every team has its own style, and Odown fits right in.
              Configure your Slack notifications exactly the way you need, 
              ensuring you get the right info at the right time—without noise or distraction.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Channel-Specific Alerts: </strong>
                Send critical alerts to #devops or #on-call, while status updates 
                can go to #general or #support.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Granular Alert Levels: </strong> 
                Set thresholds for warning vs. critical incidents, so your team knows 
                which ones need urgent attention.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Adjustable Frequency: </strong> 
                Control how often alerts appear, from immediate notifications to summarized updates.
                </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const A360ViewOfPerformanceSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">A 360° View of Performance</h3>
              <p className="u-main-feature_tc-desc">
              Downtime is only part of the story. Odown continuously 
              monitors a range of metrics—including response times, server performance, 
              and global availability—giving you a comprehensive overview of your site’s health.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Performance Trends: </strong>
                Spot slow response times before they escalate.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Geographical Outage Alerts: </strong> 
                Know if issues affect specific regions or user groups.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Maintenance Windows: </strong> 
                Schedule planned downtime and get timely reminders, so your team is always prepared.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Howtouse = () => {
    return (
      <section className="u-main-feature integrations-slack-section" style={{ padding: '30px 20px' }}>
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="content-title1">Simple Setup, Quick Results</h2>
              <p className="u-main-feature_tc-desc2">
              Getting started with Odown’s Slack Integration couldn’t be easier. 
              In just a few clicks, you can have real-time monitoring alerts 
              flowing into Slack channels—empowering your team with immediate 
              and actionable insights. 
              </p>
    
              <p className="use-desc2">
              Go to Integrations:
              Log in to your Odown dashboard and navigate to the “Integrations” section.
              </p>
              <p className="use-desc2">
              Select Slack:
              Authorize the connection between Odown and your Slack workspace.
              </p>
              <p className="use-desc2">
              Choose Your Settings:
              Pick the channels for alerts, configure alert severity, and set notification frequency.              
              </p>
              <p className="use-desc2">
              Start Monitoring:
              Watch as Odown’s real-time alerts begin appearing in Slack—so you’ll never miss another issue.              
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const SuperchargeYourIncidentResponseSection = () => {
    const features = [
      {
        title: "Centralized Visibility",
        description: " Everyone sees the same alert at the same time, ensuring swift, coordinated action.",
      },
      {
        title: "Less Context Switching",
        description: "Stay in one platform for all critical discussions—no more juggling multiple apps.",
      },
      {
        title: "Better Team Alignment",
        description: "Resolve issues quicker, free up resources, and keep customers happy.",
      },
    ];
  
    return (
      <section className="u-advanced-features" style={{ padding: '30px 20px' }}>
        <div className="container">
          <h2 className="content-title1">Supercharge Your Incident Response</h2>
          <div className="u-advanced-features__items">
          <p className="u-main-feature_tc-desc2">
          By pairing Odown with Slack, you’re turning downtime management 
          into a collaborative, real-time conversation. No more lengthy email 
          threads or lost messages—just instant, transparent communication 
          that helps resolve issues faster.
       </p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <div className="u-a-features__item-wrapper">
                    <div className="u-a-features__item--title">
                      {feature.title}
                    </div>
                    <div className="u-a-features__item--desc">
                      {feature.description}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  };
 
  const BuiltforTeamsofAllSizes = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Built for Teams of All Sizes</h2>
    <p className="u-main-feature_tc-desc2">
    Whether you’re a scrappy startup or a global enterprise, 
    Odown’s Slack Integration scales with your needs. 
    Small teams love the simplicity and speed, while larger 
    organizations benefit from role-based alerts and precise 
    channel management—making it the perfect fit for DevOps, 
    IT, and customer support teams alike.  
       </p>
     </div>
  </section>
)


const SecureandReliable = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Secure & Reliable</h2>
    <p className="u-main-feature_tc-desc2">
    Your data’s security is our top priority. 
    Odown uses industry-standard encryption and 
    best practices to safeguard your monitoring data. 
    Our Slack Integration operates with minimal permissions, 
    ensuring you have full control over what’s shared and who can access it.    
       </p>
     </div>
  </section>
)


const TryOdownSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Try Odown’s Slack Integration Today</h2>
    <p className="u-main-feature_tc-desc2">
    Don’t wait for the next downtime crisis. Harness the power of real-time 
    monitoring and team collaboration by connecting Odown to your Slack 
    workspace now. With seamless setup, flexible configuration options, 
    and best-in-class incident management, you’ll be ready to tackle 
    any outage before it spirals into a bigger problem.
    Need Support or Have Questions? Our friendly, 
    knowledgeable support team is here to help you get the most out of Odown’s 
    Slack Integration. Reach out anytime for setup assistance, best practices, or troubleshooting tips.
       </p>
     </div>
  </section>
)

const ClosingSection = () => (
    <div className="sections-container-closing" style={{ padding: '30px 20px' }}>
        <h2 className="centered-heading">
        Stop letting downtime catch you off guard. 
        Upgrade your team’s workflow with Odown’s 
        Slack Integration and keep your site running at its best!
        </h2>
    </div>
);


export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "slack_icon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    
  }
`

export default SlackIntegrationPage